<template>
  <Steps :current="step">
    <Step title="编辑"></Step>
    <Step title="一审" v-if="!isSkip"></Step>
    <Step title="二审" v-if="!isSkip"></Step>
    <Step title="三审" v-if="!isSkip"></Step>
    <Step title="配图"></Step>
    <Step title="配音"></Step>
    <Step title="系统"></Step>
    <Step title="翻译"></Step>
    <Step title="六审"></Step>
    <Step title="完成"></Step>
  </Steps>
</template>

<script>
export default {
  props: {
    taskName: {},
    isSkip: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    step() {
      let taskName = this.taskName;
      // 翻译和终审要换个位置  6审，翻译，系统，互换
      if (8 === taskName) {
        taskName = 6;
      } else if (6 === taskName) {
        taskName = 8;
      }

      if (
        0 === taskName ||
        1 === taskName ||
        2 === taskName ||
        3 === taskName
      ) {
        return taskName;
      }
      if (
        4 === taskName ||
        5 === taskName ||
        6 === taskName ||
        7 === taskName ||
        8 === taskName ||
        9 === taskName
      ) {
        if (this.isSkip) {
          return taskName - 3;
        } else {
          return taskName;
        }
      }
    }
  }
};
</script>

<style scoped>
</style>